$uiblack: #0c120c;
$uiprimary50: #fff4f2;
$uiprimary100: #ffe9ed;
$uiprimary300: #ffbdca;
$uiprimary400: #FF4F72;
$uiprimary500: #ff234f;
$uiprimary600: #cc1c3f;
$uiprimary700: #99152f;
$rojored: #e0002d;
$darkred: #c40028;
$uigray50: #f8f8f8;
$uigray100: #f6f6f6;
$uigray200: #dddddd;
$uigray400: #8d8d8d;
$uigray500: #667085;
$uigray900: #101828;
$mainboxshadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.08);
$inputshadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
