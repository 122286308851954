@use './variables' as *;

//=== GENERAL ===//

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

:root {
	font-size: 62.5%;
}

body {
	font-family: 'Noto Sans', sans-serif;
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 1.5;
	color: $uiblack;

	&.no-scroll {
		overflow: hidden;
		touch-action: none;
		-ms-touch-action: none;
	}
}

// Headings & text styles

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 700;
	line-height: 1.2;
}

.text-xs {
	font-size: 1.2rem;
	line-height: 1.2;
}

.text-sm {
	font-size: 1.4rem;
}

.text-uc {
	font-size: 1.4rem;
	font-weight: 700;
	line-height: 1.2;
	letter-spacing: 1.4px;
	text-transform: uppercase;
}

ul {
	list-style: none;
}

// Buttons & Links

button {
	font-family: inherit;
	background: none;
	border: none;
	cursor: pointer;
}

.btn {
	display: inline-block;
	border-radius: 30px;
	font-size: 1.6rem;
	line-height: 1.5;
	text-align: center;

	&--primary {
		padding: 8px 20px;
		background-color: $uiprimary500;
		font-weight: 700;
		color: #fff;
	}

	&--secondary {
		padding: 8px 20px;
		border: 1px solid $uiprimary500;
		color: $uiprimary500;
	}

	&--white {
		padding: 8px 24px;
		background-color: #fff;
		font-weight: 700;
		color: $uiprimary500;
	}
}

.subscribe-btn {
	width: 100%;
	border-radius: 0px 0px 8px 8px;
}

a,
a:active,
a:visited {
	text-decoration: none;
}

.link {
	&--primary,
	&--primary:active,
	&--primary:visited {
		display: flex;
		align-items: center;
		font-weight: 700;
		color: $uiprimary500;

		img {
			margin-right: 8px;
		}
	}

	&--black,
	&--black:active,
	&--black:visited {
		color: $uiblack;
	}

	&--red,
	&--red:active,
	&--red:visited {
		font-weight: 700;
		color: $uiprimary500;
	}
}

// Inputs

input,
select,
textarea {
	padding: 10px 12px;
	font-family: 'Noto Sans', sans-serif;
	font-size: 1.6rem;
	background-color: #fff;
	border: 1px solid $uigray200;
	border-radius: 8px;
	box-shadow: $inputshadow;
	outline: none;

	&:hover {
		border: 1px solid $uiprimary300;
		// box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	}

	&:focus {
		border: 1px solid $uiprimary300;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px $uiprimary100;
	}

	&:disabled {
		background-color: $uigray200;
	}

	&::placeholder {
		font-size: 1.6rem;
		font-family: 'Noto Sans', sans-serif;
		color: $uigray400;
	}
}

input {
	&[type='email'] {
		padding-left: 35px;
		background: url('../svgs/icons/envelope-simple.svg') no-repeat 10px;
	}

	&#website {
		padding-left: 35px;
		background: url('../svgs/icons/globe-icon-bw.svg') no-repeat 10px;
	}

	&#newsletter {
		border-radius: 8px 8px 0px 0px;
	}

	&[type='checkbox'] {
		// margin-right: 8px;
		box-shadow: none;
	}
}

select {
	padding-right: 35px;
	background: url('../svgs/icons/icon-caret-down.svg') no-repeat right 10px center;
	appearance: none;
}

textarea {
	resize: none;
}

// Modal

.modal {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	visibility: hidden;
	z-index: 200;

	&__backdrop {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.7);
		backdrop-filter: blur(10px);
		opacity: 0;
		transition: opacity 0.3s;
		z-index: -1;
	}

	&__content {
		padding: 30px 20px;
		position: relative;
		max-width: 90%;
		background-color: #fff;
		border-radius: 20px;
		transform: translateY(-10%);
		opacity: 0;
		transition: transform 0.3s 0.1s, opacity 0.3s 0.1s;
	}

	&__close {
		padding: 10px;
		position: absolute;
		top: 0;
		right: 0;
	}

	&__title {
		margin: 0 auto 3rem;
		text-align: center;
	}

	&.active {
		visibility: visible;

		.modal__backdrop {
			opacity: 1;
		}

		.modal__content {
			transform: translateY(0);
			opacity: 1;
		}
	}
}

.form {
	&__box {
		margin-bottom: 1.6rem;

		&:last-child {
			margin-bottom: 0;
		}

		input:not([type='checkbox']),
		select,
		textarea {
			width: 100%;
		}

		.error-text {
			// margin: 0 0 5px 10px;
			margin-bottom: 5px;
			color: $uiprimary500;
			visibility: hidden;
		}

		&.error {
			.error-text {
				visibility: visible;
			}

			input,
			select,
			textarea {
				border-color: $uiprimary500;
			}
		}
	}

	&__consent-box {
		display: flex;
		gap: 8px;

		a {
			color: $uiprimary500;
		}
	}

	&__btn {
		width: 100%;

		&--submit {
			margin-bottom: 2.4rem;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 8px;
		}

		&--cancel {
			font-weight: 700;
			color: $uiprimary500;
		}
	}
}

// .klaro .cookie-modal .cm-btn.cm-btn-success {
// 	background-color: $uiprimary500!important;
// }

// .klaro .cookie-modal .cm-btn {
// 	padding: 8px 20px!important;
// 	border-radius: 30px!important;
// }

// COOKIE ALERT
.cookie-alert {
	padding: 20px;
	position: fixed;
	left: 20px;
	right: 20px;
	bottom: 20px;
	display: flex;
	flex-direction: column;
	font-size: 1.2rem;
	text-align: center;
	background-color: rgba(#fff, 0.5);
	backdrop-filter: blur(10px);
	border: 2px solid $uiprimary500;
	opacity: 0;
	transform: translateY(150%);
	animation: showCookie 0.5s 0.5s forwards;
	z-index: 120;

	&__btn {
		margin-top: 2em;
		padding: 0.8em 1.6em;
		font-size: 1.2rem;
		color: #fff;
		background-color: $uiprimary500;
		border: 2px solid transparent;
		border-radius: 50px;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		transition: background-color 0.3s, border-color 0.3s, color 0.3s;

		&:hover {
			background-color: #fff;
			border-color: $uiprimary500;
			color: $uiprimary500;
		}
	}

	&.hidden {
		display: none;
	}
}

.google-slides-wrapper {
	// position: relative;
	// // margin-top: 140px;
	// padding-top: 60%;
	// width: 100%;
	// overflow: hidden;

	iframe {
		// position: absolute;
		// top: 0;
		// left: 0;
		width: 100%;
		height: calc(50vh - 120px);
		// height: 100%;
	}
}

// Hamburger

.hamburger {
	padding: 8px 16px;
	// position: fixed;
	// top: 59px;
	// right: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 30px;
	background-color: $uiprimary100;
	// z-index: 110;

	&__box {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 20px;
	}

	&__bar {
		top: 46%;
		transition: transform 0.3s;
		transition-delay: 0;

		&,
		&::before,
		&::after {
			position: absolute;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 2px;
			background-color: $uiprimary500;
			border-radius: 2px;
		}

		&::before,
		&::after {
			content: '';
		}

		&::before {
			bottom: 6px;
			transition: bottom 0.3s, transform 0.3s;
			transition-delay: 0.3s, 0s;
		}

		&::after {
			top: 6px;
			transition: top 0.3s, opacity 0.3s;
			transition-delay: 0.3s, 0.3s;
		}
	}

	&.active {
		.hamburger__bar {
			transform: rotate(225deg);
			transition-delay: 0.3s;

			&::before {
				bottom: 0;
				transform: rotate(-90deg);
				transition-delay: 0s, 0.3s;
			}

			&::after {
				top: 0;
				opacity: 0;
				transition-delay: 0s, 0.3s;
			}
		}
	}
}

// Nav

.nav {
	&--desktop {
		display: none;
	}

	&--mobile {
		padding: 120px 16px;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #fff;
		transform: translateX(102%);
		// transition: transform 0.4s;
		transition: transform 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
		transition-delay: 0.4s;
		overflow-y: scroll;
		z-index: 90;

		.nav__list {
			transform: translateY(5%);
			transition: transform 0.4s cubic-bezier(0.77, 0.86, 0.54, 0.98), opacity 0.4s;
			transition-delay: 0s, 0s;
			transition-property: transform, opacity;
			opacity: 0;
		}

		&.active {
			transition-delay: 0s;
			transform: translateX(0);

			.nav__list,
			.nav__btns {
				transition-delay: 0.4s, 0.4s;
				transform: translateY(0);
				opacity: 1;
			}
		}
	}

	&__sublist {
		padding: 24px;
		background-color: #fff;
		border-radius: 20px;
	}

	&__item {
		margin: 8px 10px;

		&--res-hub .nav__submenu {
			display: none;
		}
	}

	&__subitem {
		margin-bottom: 1.6rem;
		display: flex;
		align-items: center;

		&:last-child {
			margin: 0;
		}

		img {
			margin-right: 8px;
		}
	}

	&__link {
		padding: 6px;
		display: inline-block;
		color: $uiblack;
		transition: color 0.2s;
	}

	&__btns {
		margin: 18px;
		display: flex;
		gap: 8px;
		transform: translateY(35%);
		transition: transform 0.4s cubic-bezier(0.77, 0.86, 0.54, 0.98), opacity 0.4s;
		transition-delay: 0s, 0s;
		transition-property: transform, opacity;
		opacity: 0;
	}
}

span.nav__link {
	display: flex;
	cursor: pointer;
}

// Header

.header {
	padding: 0 16px;
	position: fixed;
	top: 0;
	width: 100%;
	background-color: #fff;
	transition: transform 0.3s, background-color 0.3s, box-shadow 0.3s;
	z-index: 100;

	&__ncbr {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 24px;
		height: 45px;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 64px;
	}

	&__logo {
		display: block;
		width: 135px;
		height: 24px;
	}

	&__btns {
		display: none;
	}

	&.white-bg {
		transform: translateY(-45px);
	}
}

// Sections

.section {
	position: relative;
	padding: 50px 16px;

	&.no-pt {
		padding-top: 0;
	}

	&__title {
		margin-bottom: 1.6rem;
		font-size: 3.2rem;
		text-align: center;

		&.no-subtitle {
			margin-bottom: 4rem;
		}

		&.tal {
			text-align: left;
		}

		&.tac {
			text-align: center;
		}
	}

	&__subtitle {
		text-align: center;
		margin-bottom: 4rem;

		&.tal {
			text-align: left;
		}

		&.tac {
			text-align: center;
		}

		&.no-mb {
			margin-bottom: 0;
		}
	}

	&__cta {
		display: flex;
		justify-content: center;

		&.centered {
			justify-content: center;
		}
	}

	&__illu {
		max-width: 100%;
		height: auto;
	}

	&--text {
		margin-top: 80px;

		p,
		ol {
			margin: 1em 0;
		}

		ol {
			padding-left: 20px;
		}

		td {
			padding: 10px;
			border: 1px solid $uiblack;
		}

		.section__subtitle {
			margin: 4rem 0 1.6rem;
		}
	}

	&--red {
		background: url('../img/red-layer-2.png') center;
		background-size: cover;
		color: #fff;
	}

	&--gray {
		background-color: $uigray100;
	}

	&--pink {
		background-color: $uiprimary50;
	}

	&.slides {
		padding-top: 110px;
	}
}

// Cards & Tags

.card {
	margin-bottom: 4rem;
	padding: 24px;
	background-color: #fff;
	box-shadow: $mainboxshadow;
	border-radius: 20px;

	&__icon {
		margin: 0 0 2.4rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 64px;
		height: 64px;
		border: 1px solid $uigray200;
		border-radius: 8px;
	}

	&__logo {
		margin-bottom: 2.4rem;
		display: flex;
		justify-content: center;
	}

	&__title {
		font-size: 2.4rem;
	}

	&__title,
	&__text {
		margin-bottom: 1.6rem;
	}

	&__tags {
		display: flex;
		justify-content: space-between;
	}
}

.tag {
	padding: 4px;
	display: inline-block;
	background-color: $uiprimary50;
	border-radius: 4px;
	font-size: 1.2rem;
	color: $uiprimary400;
}

// Accordion

.accordion {
	&__box {
		margin-bottom: 16px;
		padding: 24px;
		background-color: #fff;
		border-radius: 20px;
		box-shadow: $mainboxshadow;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__btn {
		padding: 10px;
		width: 45px;
		height: 45px;

		&-box {
			position: relative;
			display: inline-block;
			width: 25px;
			height: 25px;
			border: 1px solid $uiprimary500;
			border-radius: 25px;
		}

		&-bar {
			top: 46%;
			transition: transform 0.3s;
			transition-delay: 0;

			&,
			&::before {
				position: absolute;
				left: 10%;
				display: inline-block;
				width: 80%;
				height: 2px;
				background-color: $uiprimary500;
				border-radius: 2px;
			}

			&::before {
				content: '';
			}

			&::before {
				transform: rotate(90deg);
				transition: transform 0.3s;
			}
		}

		&.active {
			.accordion__btn-bar::before {
				transform: rotate(0);
			}
		}
	}

	&__title {
		font-size: 1.8rem;
		text-align: left;
	}

	&__info {
		max-height: 0;
		transition: max-height 0.15s;
		overflow: hidden;

		&.active {
			max-height: 600px;
			transition: max-height 0.3s 0.05s;
		}
	}
}

// Keen Slider

.keen-slider {
	&__nav {
		display: none;
	}

	&__dots {
		display: none;
	}
}

// Hero

.hero {
	margin-top: 45px;
	padding: 145px 16px;
	overflow-x: hidden;

	&__bg {
		background: url('../img/hero.jpg') center;
		background-size: cover;
	}

	&--right {
		display: none;
	}

	&__title {
		margin-bottom: 2.4rem;
		font-size: 3.2rem;
		text-align: center;

		&.tac {
			text-align: center;
		}
	}

	&__btn {
		position: relative;
		z-index: 10;

		&::before {
			content: '';
			position: absolute;
			top: 20px;
			right: -10px;
			display: block;
			width: 28px;
			height: 30px;
			border: 1px solid $uiprimary500;
			border-width: 0 1px 1px 0;
			border-radius: 0 0 28px 0;
		}
	}
}

.our-partners {
	margin-top: 9rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	p {
		margin-bottom: 1.6rem;
	}

	&__logos {
		display: flex;
		align-items: flex-start;
		gap: 20px;
	}
}

.numbers {
	text-align: center;
	background: url('../img/red-layer-1.png') center;
	background-size: cover;
	border-radius: 40px 40px 0px 0px;
	color: #fff;

	& > div {
		padding-bottom: 4rem;

		&:last-child {
			padding-bottom: 0;
		}
	}

	&__num {
		margin-bottom: 4px;
		font-size: 4.8rem;
		font-weight: 700;
		line-height: 1.2;
		letter-spacing: -1px;
		text-align: center;
	}
}

.opinions {
	padding: 0 0 120px 16px;

	&__slider {
		margin-bottom: 4rem;
	}

	&__slide-content {
		position: relative;
		padding: 24px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 1.6rem;
		min-height: 100%;
		border-radius: 20px;
		background-color: $uiprimary50;
		transform: scale(0.9);
		transform-origin: bottom center;
		opacity: 0.7;
		transition: transform 0.3s, opacity 0.3s;
	}

	&__slide.active &__slide-content {
		transform: scale(1);
		opacity: 1;
	}

	&.section--gray &__slide-content {
		background-color: #fff;
	}

	&__slide-bottom {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}

	&__client {
		display: flex;
		align-items: center;
		gap: 16px;
	}

	&__client-img {
		min-width: 52px;
		height: 52px;
		border-radius: 50%;
		overflow: hidden;
	}

	&__client-name {
		font-weight: 700;
	}

	&__slide-quote {
		display: none;
	}
}

.cta-section {
	padding: 80px 16px;

	&__employee {
		display: none;
	}
}

.footer {
	&__newsletter-box {
		padding: 48px 16px;
		background-color: $uigray50;
	}

	&__newsletter-text {
		margin-bottom: 2.4rem;

		// img {
		// 	margin-right: 16px;
		// }

		h2 {
			font-size: 2.4rem;
			color: $uigray900;
			text-align: left;
		}

		p {
			color: $uigray400;
		}
	}

	&__content {
		padding: 40px 16px 64px;

		h3 {
			font-size: 2rem;
			text-align: center;
		}

		&--left {
			margin-bottom: 6.4rem;

			& > div {
				margin-bottom: 3.2rem;
			}
		}
	}

	&__list {
		margin-top: 1.6rem;
	}

	&__list-item {
		margin-bottom: 1rem;
	}

	&__link {
		padding: 6px 0;
		display: block;
		text-align: center;
		color: $uigray500;
	}

	&__logo {
		margin-bottom: 1.6rem;
	}

	&__copyright,
	&__address {
		margin-bottom: 4rem;
		font-style: normal;
		color: $uigray500;
	}

	&__bottom {
		padding: 24px;
		background-color: $uigray50;
		border-top: 1px solid $uigray200;

		&--left {
			display: flex;
			justify-content: space-evenly;
		}

		&--right {
			margin-bottom: 2.4rem;
		}

		img {
			display: block;
		}
	}

	&__bottom-list {
		li {
			margin: 0 24px;
		}

		a {
			padding: 12px 0;
		}
	}

	&__sm-links {
		margin-bottom: 2.4rem;
		display: flex;
		justify-content: center;
		gap: 24px;
	}
}

// === MEDIA QUERIES === //

@media (min-aspect-ratio: 1.2/1) {
	.google-slides-wrapper iframe {
		height: calc(100vh - 120px);
	}
}

@media (min-aspect-ratio: 1.2/1) and (min-width: 1200px) {
	.google-slides-wrapper iframe {
		height: calc(100vh - 150px);
	}
}

@media (hover: hover) {
	.btn {
		&--primary {
			transition: background-color 0.2s;

			&:hover {
				background-color: $uiprimary600;
			}
		}

		&--secondary {
			transition: background-color 0.2s, color 0.2s;

			&:hover {
				background-color: $uiprimary500;
				color: #fff;
			}
		}

		&--white {
			transition: background-color 0.2s, color 0.2s;

			&:hover {
				background-color: $uiprimary700;
				color: #fff;
			}
		}
	}

	.nav {
		&__submenu {
			visibility: hidden;
			max-height: 0;
			opacity: 0;
			transition: max-height 0.3s, opacity 0.3s, visibility 0.3s;
		}

		&__item {
			margin: 10px;

			&--yplatform {
				.nav__link:hover ~ .nav__submenu,
				.nav__submenu:hover {
					visibility: visible;
					opacity: 1;
					max-height: 600px;
				}
			}
		}

		&__link {
			&:hover {
				color: $uiprimary500;
			}
		}
	}
}

@media (min-width: 768px) {
	.cookie-alert {
		width: 390px;
		left: unset;
	}

	// .hamburger {
	// 	right: 60px;
	// }

	.nav--mobile {
		padding: 120px 60px;
	}

	.header {
		padding: 0 60px;
	}

	.section {
		padding: 60px;
	}

	.hero {
		padding: 145px 60px;
	}

	.numbers {
		padding: 50px 60px;
		display: flex;
		justify-content: space-evenly;

		& > div {
			padding-bottom: 0;
		}
	}

	.opinions {
		padding: 0 0 60px 60px;
	}

	.footer {
		&__newsletter-box {
			padding: 48px 60px;
		}

		&__content {
			padding: 64px 60px 40px;

			&--left {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				row-gap: 32px;
			}
		}
	}
}

@media (min-width: 992px) {
	.subscribe-btn {
		width: auto;
		border-radius: 0px 8px 8px 0px;
	}

	input#newsletter {
		border-radius: 8px 0px 0px 8px;
	}

	.modal {
		&__content {
			padding: 40px 80px;
			border-radius: 60px;
		}

		&__close {
			top: 30px;
			right: 30px;
		}

		&__title {
			margin: 0 auto 4rem;
			max-width: 75%;
		}
	}

	// .hamburger {
	// 	right: 80px;
	// }

	.nav--mobile {
		padding: 120px 80px;
	}

	.header {
		padding: 0 80px;
	}

	// Sections Large

	.section {
		padding: 100px 80px;

		&__title {
			text-align: left;
		}

		&__subtitle {
			text-align: left;
		}

		&__cta {
			justify-content: start;
		}
	}

	.accordion__title {
		font-size: 2rem;
	}

	.keen-slider {
		&__nav {
			position: absolute;
			display: flex;
			gap: 16px;
			top: 0;
			right: 80px;
		}

		&__prev,
		&__next {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 56px;
			height: 56px;
			border-radius: 50%;
		}

		&__prev {
			border: 1px solid $uiprimary500;
		}

		&__next {
			background-color: $uiprimary500;
		}

		&__dots {
			padding: 0 80px 0 0;
			display: flex;
		}

		&__dot {
			width: 100%;
			height: 2px;
			background-color: $uiprimary500;
			opacity: 0.1;
			transition: opacity 0.2s;

			&.active {
				opacity: 1;
			}
		}
	}

	.hero {
		display: flex;
		justify-content: center;

		&--left {
			padding-top: 100px;
		}

		&--right {
			display: block;
		}

		&__title {
			margin-bottom: 2.4rem;
			font-size: 4.8rem;
			text-align: left;
		}
	}

	.our-partners {
		align-items: flex-start;
	}

	.numbers {
		padding: 50px 80px;
		// display: flex;
		// justify-content: space-evenly;
		border-radius: 60px 60px 0px 0px;

		// & > div {
		// 	padding-bottom: 0;
		// }
	}

	.opinions {
		padding: 0 0 120px 80px;

		&__slide-content {
			padding: 48px;
		}

		&__slide-quote {
			display: block;
		}
	}

	.cta-section {
		padding: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 40px;

		&__employee {
			// margin-right: 40px;
			display: block;
			min-width: 260px;
		}

		&__employee-img {
			position: relative;
		}

		&__img-shadow {
			position: absolute;
			width: 100%;
			height: 100%;
			background: linear-gradient(180deg, rgba(255, 255, 255, 0) 45%, rgba(196, 0, 40, 1) 92%);
		}

		&__employee-name {
			font-weight: 700;
		}

		&__employee-name,
		&__employee-pos {
			text-align: center;
		}
	}

	.footer {
		&__newsletter-box {
			padding: 50px 80px;
			display: flex;
			justify-content: space-between;
			gap: 80px;
		}

		&__newsletter-text {
			margin-bottom: 0;
			display: flex;
			align-items: center;
			gap: 16px;
		}

		&__newsletter-form {
			display: flex;
			align-items: center;
		}

		&__newsletter-form-box {
			display: flex;
		}

		&__content {
			padding: 64px 80px 40px;
			display: grid;
			grid-template-columns: 3fr 1fr;

			h3 {
				text-align: left;
			}

			&--left {
				grid-template-columns: repeat(3, 1fr);
			}
		}

		&__platform {
			grid-column: 1/2;
		}
		&__services {
			grid-column: 2/3;
		}
		&__research-hub {
			grid-column: 3/4;
		}
		&__about-us {
			grid-column: 1/2;
		}
		&__join-us {
			grid-column: 2/3;
		}

		&__link {
			text-align: left;
		}

		&__bottom {
			padding: 16px 80px;
			display: flex;
			justify-content: space-between;
			gap: 32px;

			&--left {
				align-items: center;
			}

			&--right {
				margin-bottom: 0;
				display: flex;
				align-items: center;
				order: 2;
			}
		}

		&__bottom-list {
			display: flex;
		}

		&__sm-links {
			margin-bottom: 0;
		}
	}
}

@media (hover: hover) and (min-width: 1200px) {
	// .google-slides-wrapper iframe {
	// 	height: calc(100vh - 150px);
	// }

	.hamburger {
		display: none;
	}

	.nav {
		&--desktop {
			display: block;
		}

		&--mobile {
			display: none;
		}

		&__list {
			display: flex;
			align-items: flex-start;
			gap: 10px;
		}

		&__submenu {
			padding: 30px;
			position: absolute;
			left: 50%;
			transform: translate(-50%, -5%);
			transition: transform 0.3s, opacity 0.3s, visibility 0.3s;
		}

		&__item {
			margin: 0;

			&--res-hub .nav__submenu {
				display: block;
			}

			&--yplatform,
			&--res-hub {
				position: relative;

				.nav__link:hover ~ .nav__submenu,
				.nav__submenu:hover {
					visibility: visible;
					opacity: 1;
					transform: translate(-50%, 0);
				}
			}
		}

		&__link {
			padding: 5px;
			white-space: nowrap;
		}
	}

	.header {
		background: none;
		// transition: transform .3s, background-color 0.3s, box-shadow 0.3s;

		&__header {
			height: 90px;
		}

		&__btns {
			display: flex;
			gap: 16px;
		}

		&.white-bg {
			background-color: #fff;
			box-shadow: $mainboxshadow;
			transform: translateY(-45px);
		}
	}

	.section.slides {
		padding-top: 140px;
	}
}

@media (min-width: 1200px) {
	// .hamburger {
	// 	right: 100px;
	// }

	.nav--mobile {
		padding: 145px 100px;
	}

	.section {
		padding: 120px 100px;
	}

	.keen-slider {
		&__nav {
			right: 100px;
		}

		&__dots {
			padding: 0 100px 0 0;
		}
	}

	.numbers {
		padding: 50px 100px;
	}

	.opinions {
		padding: 0 0 120px 100px;
	}

	.cta-section {
		padding: 80px 100px;
	}

	.footer {
		&__newsletter-box {
			padding: 50px 100px;
			gap: 100px;
		}

		&__content {
			padding: 70px 100px 40px;
		}
	}
}

@media (min-width: 1400px) {
	// .hamburger {
	// 	right: 120px;
	// }

	.nav--mobile {
		padding: 145px 120px;
	}

	.header {
		padding: 0 120px;
	}

	.section {
		padding: 120px;
	}

	.keen-slider {
		&__nav {
			right: 120px;
		}

		&__dots {
			padding: 0 120px 0 0;
		}
	}

	.numbers {
		padding: 50px 120px;
	}

	.opinions {
		padding: 0 0 120px 120px;
	}

	.cta-section {
		padding: 80px 120px;
	}

	.footer {
		&__newsletter-box {
			padding: 60px 120px;
			gap: 120px;
		}

		&__content {
			padding: 80px 120px 40px;
		}
	}
}

@keyframes showCookie {
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
