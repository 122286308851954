* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  font-size: 62.5%;
}

body {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0c120c;
}
body.no-scroll {
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 1.2;
}

.text-xs {
  font-size: 1.2rem;
  line-height: 1.2;
}

.text-sm {
  font-size: 1.4rem;
}

.text-uc {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 1.4px;
  text-transform: uppercase;
}

ul {
  list-style: none;
}

button {
  font-family: inherit;
  background: none;
  border: none;
  cursor: pointer;
}

.btn {
  display: inline-block;
  border-radius: 30px;
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: center;
}
.btn--primary {
  padding: 8px 20px;
  background-color: #ff234f;
  font-weight: 700;
  color: #fff;
}
.btn--secondary {
  padding: 8px 20px;
  border: 1px solid #ff234f;
  color: #ff234f;
}
.btn--white {
  padding: 8px 24px;
  background-color: #fff;
  font-weight: 700;
  color: #ff234f;
}

.subscribe-btn {
  width: 100%;
  border-radius: 0px 0px 8px 8px;
}

a,
a:active,
a:visited {
  text-decoration: none;
}

.link--primary, .link--primary:active, .link--primary:visited {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #ff234f;
}
.link--primary img, .link--primary:active img, .link--primary:visited img {
  margin-right: 8px;
}
.link--black, .link--black:active, .link--black:visited {
  color: #0c120c;
}
.link--red, .link--red:active, .link--red:visited {
  font-weight: 700;
  color: #ff234f;
}

input,
select,
textarea {
  padding: 10px 12px;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.6rem;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  outline: none;
}
input:hover,
select:hover,
textarea:hover {
  border: 1px solid #ffbdca;
}
input:focus,
select:focus,
textarea:focus {
  border: 1px solid #ffbdca;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #ffe9ed;
}
input:disabled,
select:disabled,
textarea:disabled {
  background-color: #dddddd;
}
input::placeholder,
select::placeholder,
textarea::placeholder {
  font-size: 1.6rem;
  font-family: "Noto Sans", sans-serif;
  color: #8d8d8d;
}

input[type=email] {
  padding-left: 35px;
  background: url("../svgs/icons/envelope-simple.svg") no-repeat 10px;
}
input#website {
  padding-left: 35px;
  background: url("../svgs/icons/globe-icon-bw.svg") no-repeat 10px;
}
input#newsletter {
  border-radius: 8px 8px 0px 0px;
}
input[type=checkbox] {
  box-shadow: none;
}

select {
  padding-right: 35px;
  background: url("../svgs/icons/icon-caret-down.svg") no-repeat right 10px center;
  appearance: none;
}

textarea {
  resize: none;
}

.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: 200;
}
.modal__backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: opacity 0.3s;
  z-index: -1;
}
.modal__content {
  padding: 30px 20px;
  position: relative;
  max-width: 90%;
  background-color: #fff;
  border-radius: 20px;
  transform: translateY(-10%);
  opacity: 0;
  transition: transform 0.3s 0.1s, opacity 0.3s 0.1s;
}
.modal__close {
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
}
.modal__title {
  margin: 0 auto 3rem;
  text-align: center;
}
.modal.active {
  visibility: visible;
}
.modal.active .modal__backdrop {
  opacity: 1;
}
.modal.active .modal__content {
  transform: translateY(0);
  opacity: 1;
}

.form__box {
  margin-bottom: 1.6rem;
}
.form__box:last-child {
  margin-bottom: 0;
}
.form__box input:not([type=checkbox]),
.form__box select,
.form__box textarea {
  width: 100%;
}
.form__box .error-text {
  margin-bottom: 5px;
  color: #ff234f;
  visibility: hidden;
}
.form__box.error .error-text {
  visibility: visible;
}
.form__box.error input,
.form__box.error select,
.form__box.error textarea {
  border-color: #ff234f;
}
.form__consent-box {
  display: flex;
  gap: 8px;
}
.form__consent-box a {
  color: #ff234f;
}
.form__btn {
  width: 100%;
}
.form__btn--submit {
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.form__btn--cancel {
  font-weight: 700;
  color: #ff234f;
}

.cookie-alert {
  padding: 20px;
  position: fixed;
  left: 20px;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  border: 2px solid #ff234f;
  opacity: 0;
  transform: translateY(150%);
  animation: showCookie 0.5s 0.5s forwards;
  z-index: 120;
}
.cookie-alert__btn {
  margin-top: 2em;
  padding: 0.8em 1.6em;
  font-size: 1.2rem;
  color: #fff;
  background-color: #ff234f;
  border: 2px solid transparent;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}
.cookie-alert__btn:hover {
  background-color: #fff;
  border-color: #ff234f;
  color: #ff234f;
}
.cookie-alert.hidden {
  display: none;
}

.google-slides-wrapper iframe {
  width: 100%;
  height: calc(50vh - 120px);
}

.hamburger {
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: #ffe9ed;
}
.hamburger__box {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
}
.hamburger__bar {
  top: 46%;
  transition: transform 0.3s;
  transition-delay: 0;
}
.hamburger__bar, .hamburger__bar::before, .hamburger__bar::after {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 2px;
  background-color: #ff234f;
  border-radius: 2px;
}
.hamburger__bar::before, .hamburger__bar::after {
  content: "";
}
.hamburger__bar::before {
  bottom: 6px;
  transition: bottom 0.3s, transform 0.3s;
  transition-delay: 0.3s, 0s;
}
.hamburger__bar::after {
  top: 6px;
  transition: top 0.3s, opacity 0.3s;
  transition-delay: 0.3s, 0.3s;
}
.hamburger.active .hamburger__bar {
  transform: rotate(225deg);
  transition-delay: 0.3s;
}
.hamburger.active .hamburger__bar::before {
  bottom: 0;
  transform: rotate(-90deg);
  transition-delay: 0s, 0.3s;
}
.hamburger.active .hamburger__bar::after {
  top: 0;
  opacity: 0;
  transition-delay: 0s, 0.3s;
}

.nav--desktop {
  display: none;
}
.nav--mobile {
  padding: 120px 16px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: translateX(102%);
  transition: transform 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  transition-delay: 0.4s;
  overflow-y: scroll;
  z-index: 90;
}
.nav--mobile .nav__list {
  transform: translateY(5%);
  transition: transform 0.4s cubic-bezier(0.77, 0.86, 0.54, 0.98), opacity 0.4s;
  transition-delay: 0s, 0s;
  transition-property: transform, opacity;
  opacity: 0;
}
.nav--mobile.active {
  transition-delay: 0s;
  transform: translateX(0);
}
.nav--mobile.active .nav__list,
.nav--mobile.active .nav__btns {
  transition-delay: 0.4s, 0.4s;
  transform: translateY(0);
  opacity: 1;
}
.nav__sublist {
  padding: 24px;
  background-color: #fff;
  border-radius: 20px;
}
.nav__item {
  margin: 8px 10px;
}
.nav__item--res-hub .nav__submenu {
  display: none;
}
.nav__subitem {
  margin-bottom: 1.6rem;
  display: flex;
  align-items: center;
}
.nav__subitem:last-child {
  margin: 0;
}
.nav__subitem img {
  margin-right: 8px;
}
.nav__link {
  padding: 6px;
  display: inline-block;
  color: #0c120c;
  transition: color 0.2s;
}
.nav__btns {
  margin: 18px;
  display: flex;
  gap: 8px;
  transform: translateY(35%);
  transition: transform 0.4s cubic-bezier(0.77, 0.86, 0.54, 0.98), opacity 0.4s;
  transition-delay: 0s, 0s;
  transition-property: transform, opacity;
  opacity: 0;
}

span.nav__link {
  display: flex;
  cursor: pointer;
}

.header {
  padding: 0 16px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  transition: transform 0.3s, background-color 0.3s, box-shadow 0.3s;
  z-index: 100;
}
.header__ncbr {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  height: 45px;
}
.header__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
}
.header__logo {
  display: block;
  width: 135px;
  height: 24px;
}
.header__btns {
  display: none;
}
.header.white-bg {
  transform: translateY(-45px);
}

.section {
  position: relative;
  padding: 50px 16px;
}
.section.no-pt {
  padding-top: 0;
}
.section__title {
  margin-bottom: 1.6rem;
  font-size: 3.2rem;
  text-align: center;
}
.section__title.no-subtitle {
  margin-bottom: 4rem;
}
.section__title.tal {
  text-align: left;
}
.section__title.tac {
  text-align: center;
}
.section__subtitle {
  text-align: center;
  margin-bottom: 4rem;
}
.section__subtitle.tal {
  text-align: left;
}
.section__subtitle.tac {
  text-align: center;
}
.section__subtitle.no-mb {
  margin-bottom: 0;
}
.section__cta {
  display: flex;
  justify-content: center;
}
.section__cta.centered {
  justify-content: center;
}
.section__illu {
  max-width: 100%;
  height: auto;
}
.section--text {
  margin-top: 80px;
}
.section--text p,
.section--text ol {
  margin: 1em 0;
}
.section--text ol {
  padding-left: 20px;
}
.section--text td {
  padding: 10px;
  border: 1px solid #0c120c;
}
.section--text .section__subtitle {
  margin: 4rem 0 1.6rem;
}
.section--red {
  background: url("../img/red-layer-2.png") center;
  background-size: cover;
  color: #fff;
}
.section--gray {
  background-color: #f6f6f6;
}
.section--pink {
  background-color: #fff4f2;
}
.section.slides {
  padding-top: 110px;
}

.card {
  margin-bottom: 4rem;
  padding: 24px;
  background-color: #fff;
  box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}
.card__icon {
  margin: 0 0 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border: 1px solid #dddddd;
  border-radius: 8px;
}
.card__logo {
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: center;
}
.card__title {
  font-size: 2.4rem;
}
.card__title, .card__text {
  margin-bottom: 1.6rem;
}
.card__tags {
  display: flex;
  justify-content: space-between;
}

.tag {
  padding: 4px;
  display: inline-block;
  background-color: #fff4f2;
  border-radius: 4px;
  font-size: 1.2rem;
  color: #FF4F72;
}

.accordion__box {
  margin-bottom: 16px;
  padding: 24px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.08);
}
.accordion__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordion__btn {
  padding: 10px;
  width: 45px;
  height: 45px;
}
.accordion__btn-box {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 1px solid #ff234f;
  border-radius: 25px;
}
.accordion__btn-bar {
  top: 46%;
  transition: transform 0.3s;
  transition-delay: 0;
}
.accordion__btn-bar, .accordion__btn-bar::before {
  position: absolute;
  left: 10%;
  display: inline-block;
  width: 80%;
  height: 2px;
  background-color: #ff234f;
  border-radius: 2px;
}
.accordion__btn-bar::before {
  content: "";
}
.accordion__btn-bar::before {
  transform: rotate(90deg);
  transition: transform 0.3s;
}
.accordion__btn.active .accordion__btn-bar::before {
  transform: rotate(0);
}
.accordion__title {
  font-size: 1.8rem;
  text-align: left;
}
.accordion__info {
  max-height: 0;
  transition: max-height 0.15s;
  overflow: hidden;
}
.accordion__info.active {
  max-height: 600px;
  transition: max-height 0.3s 0.05s;
}

.keen-slider__nav {
  display: none;
}
.keen-slider__dots {
  display: none;
}

.hero {
  margin-top: 45px;
  padding: 145px 16px;
  overflow-x: hidden;
}
.hero__bg {
  background: url("../img/hero.jpg") center;
  background-size: cover;
}
.hero--right {
  display: none;
}
.hero__title {
  margin-bottom: 2.4rem;
  font-size: 3.2rem;
  text-align: center;
}
.hero__title.tac {
  text-align: center;
}
.hero__btn {
  position: relative;
  z-index: 10;
}
.hero__btn::before {
  content: "";
  position: absolute;
  top: 20px;
  right: -10px;
  display: block;
  width: 28px;
  height: 30px;
  border: 1px solid #ff234f;
  border-width: 0 1px 1px 0;
  border-radius: 0 0 28px 0;
}

.our-partners {
  margin-top: 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.our-partners p {
  margin-bottom: 1.6rem;
}
.our-partners__logos {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.numbers {
  text-align: center;
  background: url("../img/red-layer-1.png") center;
  background-size: cover;
  border-radius: 40px 40px 0px 0px;
  color: #fff;
}
.numbers > div {
  padding-bottom: 4rem;
}
.numbers > div:last-child {
  padding-bottom: 0;
}
.numbers__num {
  margin-bottom: 4px;
  font-size: 4.8rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -1px;
  text-align: center;
}

.opinions {
  padding: 0 0 120px 16px;
}
.opinions__slider {
  margin-bottom: 4rem;
}
.opinions__slide-content {
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.6rem;
  min-height: 100%;
  border-radius: 20px;
  background-color: #fff4f2;
  transform: scale(0.9);
  transform-origin: bottom center;
  opacity: 0.7;
  transition: transform 0.3s, opacity 0.3s;
}
.opinions__slide.active .opinions__slide-content {
  transform: scale(1);
  opacity: 1;
}
.opinions.section--gray .opinions__slide-content {
  background-color: #fff;
}
.opinions__slide-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.opinions__client {
  display: flex;
  align-items: center;
  gap: 16px;
}
.opinions__client-img {
  min-width: 52px;
  height: 52px;
  border-radius: 50%;
  overflow: hidden;
}
.opinions__client-name {
  font-weight: 700;
}
.opinions__slide-quote {
  display: none;
}

.cta-section {
  padding: 80px 16px;
}
.cta-section__employee {
  display: none;
}

.footer__newsletter-box {
  padding: 48px 16px;
  background-color: #f8f8f8;
}
.footer__newsletter-text {
  margin-bottom: 2.4rem;
}
.footer__newsletter-text h2 {
  font-size: 2.4rem;
  color: #101828;
  text-align: left;
}
.footer__newsletter-text p {
  color: #8d8d8d;
}
.footer__content {
  padding: 40px 16px 64px;
}
.footer__content h3 {
  font-size: 2rem;
  text-align: center;
}
.footer__content--left {
  margin-bottom: 6.4rem;
}
.footer__content--left > div {
  margin-bottom: 3.2rem;
}
.footer__list {
  margin-top: 1.6rem;
}
.footer__list-item {
  margin-bottom: 1rem;
}
.footer__link {
  padding: 6px 0;
  display: block;
  text-align: center;
  color: #667085;
}
.footer__logo {
  margin-bottom: 1.6rem;
}
.footer__copyright, .footer__address {
  margin-bottom: 4rem;
  font-style: normal;
  color: #667085;
}
.footer__bottom {
  padding: 24px;
  background-color: #f8f8f8;
  border-top: 1px solid #dddddd;
}
.footer__bottom--left {
  display: flex;
  justify-content: space-evenly;
}
.footer__bottom--right {
  margin-bottom: 2.4rem;
}
.footer__bottom img {
  display: block;
}
.footer__bottom-list li {
  margin: 0 24px;
}
.footer__bottom-list a {
  padding: 12px 0;
}
.footer__sm-links {
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: center;
  gap: 24px;
}

@media (min-aspect-ratio: 1.2/1) {
  .google-slides-wrapper iframe {
    height: calc(100vh - 120px);
  }
}
@media (min-aspect-ratio: 1.2/1) and (min-width: 1200px) {
  .google-slides-wrapper iframe {
    height: calc(100vh - 150px);
  }
}
@media (hover: hover) {
  .btn--primary {
    transition: background-color 0.2s;
  }
  .btn--primary:hover {
    background-color: #cc1c3f;
  }
  .btn--secondary {
    transition: background-color 0.2s, color 0.2s;
  }
  .btn--secondary:hover {
    background-color: #ff234f;
    color: #fff;
  }
  .btn--white {
    transition: background-color 0.2s, color 0.2s;
  }
  .btn--white:hover {
    background-color: #99152f;
    color: #fff;
  }
  .nav__submenu {
    visibility: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.3s, opacity 0.3s, visibility 0.3s;
  }
  .nav__item {
    margin: 10px;
  }
  .nav__item--yplatform .nav__link:hover ~ .nav__submenu,
  .nav__item--yplatform .nav__submenu:hover {
    visibility: visible;
    opacity: 1;
    max-height: 600px;
  }
  .nav__link:hover {
    color: #ff234f;
  }
}
@media (min-width: 768px) {
  .cookie-alert {
    width: 390px;
    left: unset;
  }
  .nav--mobile {
    padding: 120px 60px;
  }
  .header {
    padding: 0 60px;
  }
  .section {
    padding: 60px;
  }
  .hero {
    padding: 145px 60px;
  }
  .numbers {
    padding: 50px 60px;
    display: flex;
    justify-content: space-evenly;
  }
  .numbers > div {
    padding-bottom: 0;
  }
  .opinions {
    padding: 0 0 60px 60px;
  }
  .footer__newsletter-box {
    padding: 48px 60px;
  }
  .footer__content {
    padding: 64px 60px 40px;
  }
  .footer__content--left {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 32px;
  }
}
@media (min-width: 992px) {
  .subscribe-btn {
    width: auto;
    border-radius: 0px 8px 8px 0px;
  }
  input#newsletter {
    border-radius: 8px 0px 0px 8px;
  }
  .modal__content {
    padding: 40px 80px;
    border-radius: 60px;
  }
  .modal__close {
    top: 30px;
    right: 30px;
  }
  .modal__title {
    margin: 0 auto 4rem;
    max-width: 75%;
  }
  .nav--mobile {
    padding: 120px 80px;
  }
  .header {
    padding: 0 80px;
  }
  .section {
    padding: 100px 80px;
  }
  .section__title {
    text-align: left;
  }
  .section__subtitle {
    text-align: left;
  }
  .section__cta {
    justify-content: start;
  }
  .accordion__title {
    font-size: 2rem;
  }
  .keen-slider__nav {
    position: absolute;
    display: flex;
    gap: 16px;
    top: 0;
    right: 80px;
  }
  .keen-slider__prev, .keen-slider__next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }
  .keen-slider__prev {
    border: 1px solid #ff234f;
  }
  .keen-slider__next {
    background-color: #ff234f;
  }
  .keen-slider__dots {
    padding: 0 80px 0 0;
    display: flex;
  }
  .keen-slider__dot {
    width: 100%;
    height: 2px;
    background-color: #ff234f;
    opacity: 0.1;
    transition: opacity 0.2s;
  }
  .keen-slider__dot.active {
    opacity: 1;
  }
  .hero {
    display: flex;
    justify-content: center;
  }
  .hero--left {
    padding-top: 100px;
  }
  .hero--right {
    display: block;
  }
  .hero__title {
    margin-bottom: 2.4rem;
    font-size: 4.8rem;
    text-align: left;
  }
  .our-partners {
    align-items: flex-start;
  }
  .numbers {
    padding: 50px 80px;
    border-radius: 60px 60px 0px 0px;
  }
  .opinions {
    padding: 0 0 120px 80px;
  }
  .opinions__slide-content {
    padding: 48px;
  }
  .opinions__slide-quote {
    display: block;
  }
  .cta-section {
    padding: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
  .cta-section__employee {
    display: block;
    min-width: 260px;
  }
  .cta-section__employee-img {
    position: relative;
  }
  .cta-section__img-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 45%, rgb(196, 0, 40) 92%);
  }
  .cta-section__employee-name {
    font-weight: 700;
  }
  .cta-section__employee-name, .cta-section__employee-pos {
    text-align: center;
  }
  .footer__newsletter-box {
    padding: 50px 80px;
    display: flex;
    justify-content: space-between;
    gap: 80px;
  }
  .footer__newsletter-text {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .footer__newsletter-form {
    display: flex;
    align-items: center;
  }
  .footer__newsletter-form-box {
    display: flex;
  }
  .footer__content {
    padding: 64px 80px 40px;
    display: grid;
    grid-template-columns: 3fr 1fr;
  }
  .footer__content h3 {
    text-align: left;
  }
  .footer__content--left {
    grid-template-columns: repeat(3, 1fr);
  }
  .footer__platform {
    grid-column: 1/2;
  }
  .footer__services {
    grid-column: 2/3;
  }
  .footer__research-hub {
    grid-column: 3/4;
  }
  .footer__about-us {
    grid-column: 1/2;
  }
  .footer__join-us {
    grid-column: 2/3;
  }
  .footer__link {
    text-align: left;
  }
  .footer__bottom {
    padding: 16px 80px;
    display: flex;
    justify-content: space-between;
    gap: 32px;
  }
  .footer__bottom--left {
    align-items: center;
  }
  .footer__bottom--right {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    order: 2;
  }
  .footer__bottom-list {
    display: flex;
  }
  .footer__sm-links {
    margin-bottom: 0;
  }
}
@media (hover: hover) and (min-width: 1200px) {
  .hamburger {
    display: none;
  }
  .nav--desktop {
    display: block;
  }
  .nav--mobile {
    display: none;
  }
  .nav__list {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }
  .nav__submenu {
    padding: 30px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -5%);
    transition: transform 0.3s, opacity 0.3s, visibility 0.3s;
  }
  .nav__item {
    margin: 0;
  }
  .nav__item--res-hub .nav__submenu {
    display: block;
  }
  .nav__item--yplatform, .nav__item--res-hub {
    position: relative;
  }
  .nav__item--yplatform .nav__link:hover ~ .nav__submenu,
  .nav__item--yplatform .nav__submenu:hover, .nav__item--res-hub .nav__link:hover ~ .nav__submenu,
  .nav__item--res-hub .nav__submenu:hover {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 0);
  }
  .nav__link {
    padding: 5px;
    white-space: nowrap;
  }
  .header {
    background: none;
  }
  .header__header {
    height: 90px;
  }
  .header__btns {
    display: flex;
    gap: 16px;
  }
  .header.white-bg {
    background-color: #fff;
    box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.08);
    transform: translateY(-45px);
  }
  .section.slides {
    padding-top: 140px;
  }
}
@media (min-width: 1200px) {
  .nav--mobile {
    padding: 145px 100px;
  }
  .section {
    padding: 120px 100px;
  }
  .keen-slider__nav {
    right: 100px;
  }
  .keen-slider__dots {
    padding: 0 100px 0 0;
  }
  .numbers {
    padding: 50px 100px;
  }
  .opinions {
    padding: 0 0 120px 100px;
  }
  .cta-section {
    padding: 80px 100px;
  }
  .footer__newsletter-box {
    padding: 50px 100px;
    gap: 100px;
  }
  .footer__content {
    padding: 70px 100px 40px;
  }
}
@media (min-width: 1400px) {
  .nav--mobile {
    padding: 145px 120px;
  }
  .header {
    padding: 0 120px;
  }
  .section {
    padding: 120px;
  }
  .keen-slider__nav {
    right: 120px;
  }
  .keen-slider__dots {
    padding: 0 120px 0 0;
  }
  .numbers {
    padding: 50px 120px;
  }
  .opinions {
    padding: 0 0 120px 120px;
  }
  .cta-section {
    padding: 80px 120px;
  }
  .footer__newsletter-box {
    padding: 60px 120px;
    gap: 120px;
  }
  .footer__content {
    padding: 80px 120px 40px;
  }
}
@keyframes showCookie {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}